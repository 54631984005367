import React from 'react'

function Footer(){
  return (
    <div style={{background:'rgb(68, 89, 100,0.8)',textAlign:'center',fontSize:'small',color:'white'}}>
      Copyright &#169; 2024 Abhishek N , All rights received
    </div>
  )
}

export default Footer
